
import {ref} from "vue"
import FormEditLabel from "@/common/form_edit/FormEditLabel.vue"

// name
// value === true or false
// output === Yes [Y] or No [N]
export default {
	name: "FormEditIconButton",
	components: {FormEditLabel},
	props: [
		"id",
		"label",
		"sublabel",
		"icon",
		"value",
		"marked"
	],
	setup(props) {
		let buttonText = ref("")
		let iconClass = ref("")

		if (typeof(props.value) === "string") {
			buttonText.value = props.value.replace(/ \[(.*)\]$/, '')
		}

		switch (props.icon) {
			case "radio":
				iconClass.value = "fa-circle is-circular-border"
				break
			case "checkmark":
				iconClass.value = "has-text-info fa-check fa-xs"
				break
			default:
				iconClass.value = "has-text-info fa-check"
				break
		}

		return {buttonText, iconClass}
	}
}


export default {
	name: "FormEditHeader",
	setup(props, context) {
		const stop_id: string = context.attrs.stop_id
		const save_action = context.attrs.save_action
		const close_action = context.attrs.close_action

		return {stop_id, save_action, close_action}
	}
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withKeys as _withKeys, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51bb3de8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "control" }
const _hoisted_3 = ["id", "name"]
const _hoisted_4 = {
  class: "autocomplete-results-container",
  id: "autocomplete-results"
}
const _hoisted_5 = {
  key: 0,
  class: "loading"
}
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormEditLabel = _resolveComponent("FormEditLabel")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormEditLabel, {
      class: "is-marginless",
      for: $setup.inputId,
      label: $props.label,
      sublabel: $props.sublabel,
      action: ""
    }, null, 8, ["for", "label", "sublabel"]),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        ref: "autoCompleteRef",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.search) = $event)),
        id: $setup.inputId,
        name: $props.name,
        type: "text",
        autocomplete: "off",
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.onChange && $setup.onChange(...args))),
        onKeyup: [
          _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => ($setup.onArrowDown && $setup.onArrowDown(...args)), ["down"])),
          _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => ($setup.onArrowUp && $setup.onArrowUp(...args)), ["up"])),
          _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => ($setup.onEnter && $setup.onEnter(...args)), ["enter"]))
        ]
      }, null, 40, _hoisted_3), [
        [_vModelText, $setup.search],
        [_directive_click_outside, $setup.handleClickOutside]
      ]),
      _withDirectives(_createElementVNode("ul", _hoisted_4, [
        ($setup.isLoading)
          ? (_openBlock(), _createElementBlock("li", _hoisted_5, "Loading results..."))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($setup.results, (result, i) => {
              return (_openBlock(), _createElementBlock("li", {
                class: _normalizeClass(["autocomplete-results-suggestion", { 'is-active': i === $setup.arrowCounter }]),
                key: i,
                onClick: ($event: any) => ($setup.setResult(result))
              }, _toDisplayString(result.school || result), 11, _hoisted_6))
            }), 128))
      ], 512), [
        [_vShow, $setup.isOpen]
      ])
    ])
  ]))
}

import {computed, ref, onMounted, onUnmounted} from "vue"
import {useStore} from "vuex"
import axios from "axios"
import FormEditLabel from "@/common/form_edit/FormEditLabel.vue"
import useEmitter from "@/composables/UseEmitter"

export default {
	name: "FormEditAutoComplete",
	components: {FormEditLabel},
	emits: ['search'],
	props: {
		id: String,
		name: String,
		required: String,
		label: String,
		sublabel: String,
		value: String,
		min: String,
		max: String,
		pattern: String,
		comment: String,
		autocomplete: String,
		isAsync: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	setup(props) {
		const store = useStore()
		const emitter = useEmitter()
		const autoCompleteRef = ref(null)
		let inputId = ref(props.id)
		let isLoading = ref(false)
		let isOpen = ref(false)
		let results = ref([])
		let search = ref("")
		let arrowCounter = ref(0)
		let suggestions = ref([])

		function onChange() {
			// are we loading data from an external source?
			if (props.isAsync) {
				isLoading.value = true
			} else {
				// was nothing loaded?
				if (!suggestions.value.length) {
					// console.log("onChange: no suggestions")
					return
				}

				filterResults()
				isOpen.value = true
			}
		}

		function filterResults() {
			if (!suggestions.value.length) {
				// console.log("filterResults: no suggestions")
				return
			}
			if(typeof suggestions.value[0] == 'string'){
				results.value = suggestions.value.filter(suggestion => {
					return suggestion.toLowerCase().indexOf(search.value.toLowerCase()) > -1
				})
				
			} else{
				results.value = suggestions.value.filter(suggestion => {
					return suggestion.school.toLowerCase().indexOf(search.value.toLowerCase()) > -1
				})
			}

			
		}

		function setResult(result) {
			search.value = result.school || result
			isOpen.value = false

			if(result.street)
				emitter.emit("setSchoolAddressForm", result)
		}

		function onArrowDown() {
			if (arrowCounter.value < results.value.length) {
				arrowCounter.value = arrowCounter.value + 1
			}
		}

		function onArrowUp() {
			if (arrowCounter.value > 0) {
				arrowCounter.value = arrowCounter.value - 1
			}
		}

		function onEnter() {
			search.value = results.value[arrowCounter.value]
			isOpen.value = false
			this.arrowCounter = -1
		}

		function handleClickOutside(evt) {
			if (!autoCompleteRef.value.contains(evt.target)) {
				isOpen.value = false
				arrowCounter.value = -1
			}
		}

		function loadData(url, target, parseJson = false) {
			if (!url) {
				return
			}

			axios.get(url).then(response => {
				if (response.data) {
					if (parseJson) {
						target.value = JSON.parse(response.data)
					} else {
						target.value = response.data
					}

					return
				}
			}).catch(() => {
				return
			})
		}

		function getUrl(resource) {
			if (!resource) {
				return
			}
			// let usaState = store.getters["session/getState"]
			// let usaCounty = store.getters["session/getCounty"]

			// if (!usaState || !usaCounty) {
			// 	usaState = "CA"
			// 	usaCounty = "San Diego"
			// }
			// return location.protocol + "//" +
			// 	location.host + "/tables/" +
			// 	resource
			// 		.replace("STATE", usaState.toLowerCase())
			// 		.replace("COUNTY", usaCounty.toLowerCase())
			// 		.replace(" ", "_") + ".json"

			let url = location.protocol + "//" + location.host
       
			if(resource.includes('cities')){
				url = url + "/tables/ca/cities/cities.json"
			}
			if(resource.includes('schools')){
				url = url + "/tables/ca/schools/schoolstemp.json"
			}
			if(resource.includes('offenses')){
				url = url + '/tables/' + resource
				.replace("STATE", store.getters["session/getState"].toLowerCase())
				.replace("COUNTY", store.getters["session/getCounty"].toLowerCase())
				.replace(" ", "_") +
				".json";
			}
			return url
		}

		function loadValue() {
			search.value = props.value
		}
		
		onMounted(() => {
			if(props.name == 'city'){
				emitter.on("setSchoolAddressForm", (params) => {
					if(props.name == 'city') search.value = params.city
				})

				emitter.on("updateAddress", (params) => {
					if(props.name == 'city') search.value = params.city
				})
			}
		});



		onUnmounted(() => {
			emitter.off("setSchoolAddressForm")
			emitter.off("updateAddress")
		})

		loadData(getUrl(props.autocomplete), suggestions)
		loadValue()

		return {
			autoCompleteRef,
			search,
			inputId,
			isOpen,
			isLoading,
			arrowCounter,
			results,
			onChange,
			setResult,
			onArrowDown,
			onArrowUp,
			onEnter,
			handleClickOutside
		}
	},
	watch: {
		suggestions: function(val, oldValue) {
			if (val && oldValue && val.length !== oldValue.length) {
				this.results = val;
				this.isLoading = false;
			}
		}
	}
}

import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ee7f1de"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mb-1"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 1,
  class: "mb-2"
}
const _hoisted_4 = { class: "sub-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.labelText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", {
            for: $setup.forId,
            class: _normalizeClass($setup.className)
          }, _toDisplayString($setup.labelText), 11, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    ($setup.subLabelText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("label", _hoisted_4, _toDisplayString($setup.subLabelText), 1)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
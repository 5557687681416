
import {onMounted, onUnmounted, ref} from "vue"
import {useStore} from "vuex"

import useEmitter from "@/composables/UseEmitter"
import router from "@/router"

import FormEditHeader from "@/common/form_edit/FormEditHeader.vue"
import FormEditLabel from "@/common/form_edit/FormEditLabel.vue"
import FormEditSelectInput from "@/common/form_edit/FormEditSelectInput.vue"
import FormEditInput from "@/common/form_edit/FormEditInput.vue"
import FormEditYesNoButtons from "@/common/form_edit/FormEditYesNoButtons.vue"
import FormEditIconButton from "@/common/form_edit/FormEditIconButton.vue"
import FormEditGeoLocationButton from "@/common/form_edit/FormEditGeoLocationButton.vue"
import FormEditAutoComplete from "@/common/form_edit/FormEditAutoComplete.vue"
import axios from "axios"
import moment from "moment"

export default {
    name: "FormEdit",
    components: {FormEditHeader, FormEditLabel, FormEditSelectInput, FormEditInput, FormEditYesNoButtons, FormEditIconButton, FormEditGeoLocationButton, FormEditAutoComplete},
    setup() {
        let store = useStore()
        const emitter = useEmitter()

        let lastPerson = ref(0)
        let personOptions = []
        let errors = ref([])
        let fieldErrors = ref([])
        let submission = ref(store.getters["submission/getSubmission"])
        // let submission = ref(SubmissionExample)

        let ori = ref()
        let ripaOfficerId = ref()
        let assignment = ref()
        let experience = ref()
        let otherAssignment = ref()

        let stopId = ref()
        let date = ref()
        let time = ref()
        let stopDuration = ref()
        let stopCallService = ref()
        let outOfCounty = ref()
        let school = ref()
        let blockNumber = ref()
        let streetName = ref()
        let intersection = ref()
        let highwayExit = ref()
        let landmark = ref()
        let city = ref()
        let raceEthnicityList = ref()
        let gender = ref()
        let genderNonConforming = ref()
        let lgbt = ref()
        let age = ref()
        let limitedEnglish = ref()
        let isK12Student = ref()
        let disabilityList = ref()
        let reasonableSuspicionList = ref()
        let reasonableSuspicionCode = ref()
        let stopReason = ref()
        let stopNarrative = ref()
        let studentDisciplineSection = ref()
        let suspensionCode = ref()
        let trafficViolation = ref()
        let trafficViolationCode = ref()
        let actionsTakenList = ref()
        let searchBasisList = ref()
        let searchBasisExplanation = ref()
        let evidenceOrContrabandList = ref()
        let propertyTypeSeizedList = ref()
        let propertySeizureBasisList = ref()
        let stopResultList = ref()

        let warningCjisCodeList = ref()
        let citationCjisCodeList = ref()
        let citeAndReleaseCjisCodeList = ref()
        let arrestCjisCodeList = ref()

        let showDefaultLocation = ref(true)
        let showSchoolLocation = ref(true)
        let showResidenceLocation = ref(true)
        let showCity = ref(true)
        let url = location.protocol + "//" + location.host
        let schoolList = []
        axios.get(url + "/tables/ca/schools/schoolstemp.json").then(response => {
			if (response.data) {
                schoolList = response.data
			}
		})
        let askedForConsentToSearchPerson = ref<boolean>(false)
        let askedForConsentToSearchProperty = ref<boolean>(false)
		let searchConsentGiven = ref<boolean>(false)
		let searchConducted = ref<boolean>(false)
		enum SearchTarget {
			Person,
			Property,
			Both
		}


        function parseSubmission() {
            // invalid submission loaded during rendering?
            if (!submission.value
            || !("content" in submission.value)
            || !("form_data" in submission.value.content)
            || !("people" in submission.value.content.form_data)) {
                return false
            }

            stopId.value = submission.value.id

            let content = submission.value.content

            ori.value = content.originating_agency_identifier
            ripaOfficerId.value = content.ripa_officer_id
            assignment.value = content.assignment
            experience.value = content.experience
            otherAssignment.value = content.other_assignment

            if(otherAssignment.value == 'null'){
                otherAssignment.value = ' '
            }
            if(!assignment.value.includes('Other')){
                otherAssignment.value = ''
            }

            let formData = content.form_data
            let selectedRecord= store.getters["form_data/getFormRecord"]
            date.value = moment(formData.date).format('MM/DD/YYYY')
            time.value = formData.time
            stopDuration.value = formData.stop_duration
            stopCallService.value = formData.stop_service_call
            if(selectedRecord && selectedRecord.out_of_county && selectedRecord.out_of_county[0]){
                if(selectedRecord.out_of_county[0].includes('Out of Count')){
                    outOfCounty.value = true
                } else {
                    outOfCounty.value = false
                }
            } else {
                outOfCounty.value = formData.out_of_county
            }

            school.value = formData.school
            blockNumber.value = formData.block_number
            streetName.value = formData.street_name
            intersection.value = formData.intersection
            highwayExit.value = formData.highway_exit
            landmark.value = formData.landmark
            city.value = formData.city
            for (let personId = 0; personId < formData.people.length; ++personId) {
                personOptions.push({
                    "id": personId,
                    "name": "" + (personId + 1)
                })
            }
            loadPerson(lastPerson.value)
            return true
        }

        function loadPerson(personId) {
            let people = submission.value.content.form_data.people
            let selectedPerson = store.getters["form_data/getFormRecord"]
            if (!(personId in people)) {
                console.error("invalid person selected in submission")
                return
            }

            let person = people[personId]

            raceEthnicityList.value = Array.isArray(person.race_ethnicity) ? [...person.race_ethnicity] : []
            gender.value = person.gender
            if(selectedPerson && selectedPerson.people && selectedPerson.people[personId] && selectedPerson.people[personId].gender_nonconforming && selectedPerson.people[personId].gender_nonconforming[0]){
                if(selectedPerson.people[personId].gender_nonconforming[0].includes('Gender Nonconformin')){
                    genderNonConforming.value = true
                } else {
                    genderNonConforming.value = false
                }
            } else {
                genderNonConforming.value = person.genderNonConforming
            }
            if(selectedPerson && selectedPerson.people && selectedPerson.people[personId] && selectedPerson.people[personId].lgbt && selectedPerson.people[personId].lgbt[0]){
                if(selectedPerson.people[personId].lgbt[0].includes('Yes')){
                 
                    lgbt.value = true
                } else {
                  
                    lgbt.value = false
                }
            } else {
                lgbt.value = person.lgbt
            }
            if(selectedPerson && selectedPerson.people && selectedPerson.people[personId] && selectedPerson.people[personId].limited_english && selectedPerson.people[personId].limited_english[0]){
                if(selectedPerson.people[personId].limited_english[0].includes('Yes')){
                    limitedEnglish.value = true
                } else {
                    limitedEnglish.value = false
                }
            } else {
                limitedEnglish.value = person.limitedEnglish
            }
            if(selectedPerson && selectedPerson.people && selectedPerson.people[personId] && selectedPerson.people[personId].is_k12_student && selectedPerson.people[personId].is_k12_student[0]){
                if(selectedPerson.people[personId].is_k12_student[0].includes('Yes')){
                    isK12Student.value = true
                } else {
                    isK12Student.value = false
                }
            } else {
                isK12Student.value = person.isK12Student
            }
            age.value = person.age
            disabilityList.value = Array.isArray(person.disability) ? [...person.disability] : []
            reasonableSuspicionList.value = Array.isArray(person.reasonable_suspicion) ? [...person.reasonable_suspicion] : []
            reasonableSuspicionCode.value = person.reasonable_suspicion_code
            stopReason.value = person.stop_reason
            stopNarrative.value = person.stop_narrative            
            suspensionCode.value = person.suspension_code
            trafficViolation.value = person.traffic_violation
            trafficViolationCode.value = person.traffic_violation_code
            actionsTakenList.value = Array.isArray(person.actions_taken) ? [...person.actions_taken] : []

            if (Array.isArray(person.student_discipline_section)){
                if (person.student_discipline_section.length > 0){
                    studentDisciplineSection.value = person.student_discipline_section[0]
                }else{
                    studentDisciplineSection.value = ""
                }
            }else{
                studentDisciplineSection.value = person.student_discipline_section
            }

            if (actionsTakenList.value){
                Array.from(actionsTakenList.value).forEach(element => {
                    let action : string = element as string;

                    if (action && action.includes('Asked for Consent to Search Person')){
                        askedForConsentToSearchPerson.value = true;
						if (action.includes('Y"]')){
							searchConsentGiven.value = true;
						}
                    }
                    if (action && action.includes('Asked for Consent to Search Property')){
                        askedForConsentToSearchProperty.value = true;
						if (action.includes('Y"]')){
							searchConsentGiven.value = true;
						}
                    }

					if (action &&
						(action.includes('Search of Property was Conducted ["20,na"]') ||
							action.includes('Search of Person was Conducted ["18,na"]'))){
						searchConducted.value = true;
					}
                });
            }

            searchBasisList.value = Array.isArray(person.search_basis) ? [...person.search_basis] : []

			if (searchBasisList.value){
				Array.from(searchBasisList.value).forEach(element => {
					let action : string = element as string;

					if (action && action.includes('Consent Given')){
						searchConsentGiven.value = true;
					}
				})
			}

			if (searchConsentGiven.value && searchConducted.value){
				arrayAdd('searchBasisList', 'Consent Given [1]')
			}else{
				arrayRemove('searchBasisList', 'Consent Given [1]')
			}

            searchBasisExplanation.value = person.search_basis_explanation
            evidenceOrContrabandList.value = Array.isArray(person.evidence_or_contraband) ? [...person.evidence_or_contraband] : []
            propertyTypeSeizedList.value = Array.isArray(person.property_type_seized) ? [...person.property_type_seized] : []
            propertySeizureBasisList.value = Array.isArray(person.property_seizure_basis) ? [...person.property_seizure_basis] : []
            stopResultList.value = Array.isArray(person.stop_result) ? [...person.stop_result] : []
            warningCjisCodeList.value = Array.isArray(person.warning_cjis_codes) ? [
                ...person.warning_cjis_codes,
            ] : [
                person.warning_cjis_codes_1,
                person.warning_cjis_codes_2,
                person.warning_cjis_codes_3,
                person.warning_cjis_codes_4,
                person.warning_cjis_codes_5
            ]
            citationCjisCodeList.value = Array.isArray(person.citation_cjis_codes) ? [
                ...person.citation_cjis_codes,
            ] : [
                person.citation_cjis_codes_1,
                person.citation_cjis_codes_2,
                person.citation_cjis_codes_3,
                person.citation_cjis_codes_4,
                person.citation_cjis_codes_5
            ]
            citeAndReleaseCjisCodeList.value = Array.isArray(person.cite_and_release_cjis_codes) ? [
                ...person.cite_and_release_cjis_codes,
            ] : [
                person.cite_and_release_cjis_codes_1,
                person.cite_and_release_cjis_codes_2,
                person.cite_and_release_cjis_codes_3,
                person.cite_and_release_cjis_codes_4,
                person.cite_and_release_cjis_codes_5
            ]
            arrestCjisCodeList.value =  Array.isArray(person.arrest_cjis_codes) ? [
                ...person.arrest_cjis_codes,
            ] : [
                person.arrest_cjis_codes_1,
                person.arrest_cjis_codes_2,
                person.arrest_cjis_codes_3,
                person.arrest_cjis_codes_4,
                person.arrest_cjis_codes_5
            ]
        }

        function setValue(key, value, localStorageKey) {
            let selectedRecord = store.getters["form_data/getFormRecord"]
            let selectedPerson = selectedRecord.people[localStorage.getItem('activePersonRecord')]

            selectedPerson[localStorageKey] = value


            switch (key) {
                case 'stopCallService':
                    stopCallService.value = value
                    if(value == true){
                        selectedRecord['stop_service_call'] = ["Yes [Y]"]
                    } else {
                        selectedRecord['stop_service_call'] = ["No [N]"]
                    }
                    break
                case 'outOfCounty':
                    outOfCounty.value = value
                    if(value == true){
                        selectedRecord[localStorageKey] = ["Out of County"]
                    } else {
                        selectedRecord[localStorageKey] = []
                    }
                    break
                case 'isK12Student':
                    isK12Student.value = value
                    if(value == true){
                        selectedPerson[localStorageKey] = ["Yes [Y]"]
                    } else {
                        selectedPerson[localStorageKey] = ["No [N]"]
                    }
                    break
                case 'genderNonConforming':
                    genderNonConforming.value = value
                    if(value == true){
                        selectedPerson[localStorageKey] = "Gender Nonconforming [5]"
                    } else {
                        selectedPerson[localStorageKey] = []
                    }
                    break
                case 'lgbt':
                    lgbt.value = value
                    if(value == true){
                        selectedPerson[localStorageKey] = ["Yes [Y]"]
                    } else {
                        selectedPerson[localStorageKey] = ["No [N]"]
                    }
                    break
                case 'limitedEnglish':
                    limitedEnglish.value = value
                    if(value == true){
                        selectedPerson[localStorageKey] = ["Yes [Y]"]
                    } else {
                        selectedPerson[localStorageKey] = ["No [N]"]
                    }
                    break
                default:
                    break
            }
            store.dispatch("form_data/setFormRecord", selectedRecord)
        }

        function updateArrayValue(arrayName, value): Array<string> {
            switch (arrayName) {
                case 'actionsTakenList':
                    actionsTakenList.value = value
                    break
                case 'disabilityList':
                    disabilityList.value = value
                    break
                case 'evidenceOrContrabandList':
                    evidenceOrContrabandList.value = value
                    break
                case 'propertySeizureBasisList':
                    propertySeizureBasisList.value = value
                    break
                case 'propertyTypeSeizedList':
                    propertyTypeSeizedList.value = value
                    break
                case 'raceEthnicityList':
                    raceEthnicityList.value = value
                    break
                case 'reasonableSuspicionList':
                    reasonableSuspicionList.value = value
                    break
                case 'searchBasisList':
                    searchBasisList.value = value
                    break
                case 'stopResultList':
                    stopResultList.value = value
                    break
                default:
                    return []
            }
        }

        function getArrayByName(arrayName) {
            switch (arrayName) {
                case 'actionsTakenList':
                    return actionsTakenList.value
                case 'disabilityList':
                    return disabilityList.value
                case 'evidenceOrContrabandList':
                    return evidenceOrContrabandList.value
                case 'propertySeizureBasisList':
                    return propertySeizureBasisList.value
                case 'propertyTypeSeizedList':
                    return propertyTypeSeizedList.value
                case 'raceEthnicityList':
                    return raceEthnicityList.value
                case 'reasonableSuspicionList':
                    return reasonableSuspicionList.value
                case 'searchBasisList':
                    return searchBasisList.value
                case 'stopResultList':
                    return stopResultList.value
                default:
                    return []
            }
        }

		function toggleAskedForConsentToSearchPerson(): void {
			askedForConsentToSearchPerson.value = !askedForConsentToSearchPerson.value;

			//if asked for consent is not marked, we need to make sure all values of consent to search are removed from the actions taken list
			if (askedForConsentToSearchPerson.value !== true) {
				const arrayName = "actionsTakenList";
				let lowerCaseValue: string = "Asked for Consent to Search Person".toLowerCase();
				let array: Array<string> = getArrayByName(arrayName);

				if (array.length > 0) {
					for (let index = 0; index < array.length; ++index) {
						if (array[index].toLowerCase().includes(lowerCaseValue)) {
							array.splice(index, 1);
						}
					}

					updateArrayValue(arrayName, array);
				}
			}

			evaluateSearchBasisConsentGiven();
		}

		function toggleAskedForConsentToSearchProperty():void {
			askedForConsentToSearchProperty.value = !askedForConsentToSearchProperty.value;

			if (!askedForConsentToSearchProperty.value) {
				const arrayName = "actionsTakenList";
				let lowerCaseValue =
					"Asked for Consent to Search Property".toLowerCase();
				let array: Array<string> = getArrayByName(arrayName);

				if (array.length > 0) {
					for (let index = 0; index < array.length; ++index) {
						if (array[index].toLowerCase().includes(lowerCaseValue)) {
							array.splice(index, 1);
						}
					}

					updateArrayValue(arrayName, array);
				}
			}

			evaluateSearchBasisConsentGiven();
		}

		function evaluateSearchConducted(): boolean{
			if (arrayIncludes('actionsTakenList', 'Search of Property was Conducted ["20,na"]') ||
				arrayIncludes('actionsTakenList', 'Search of Person was Conducted ["18,na"]')){
				searchConsentGiven.value = true;
				return true;
			}else{
				let sbArray = [];
				updateArrayValue('searchBasisList', sbArray);
				return false;
			}
		}

		function evaluateSearchBasisConsentGiven(): boolean {
			if (searchConducted.value === true &&
				(arrayIncludes('actionsTakenList', 'Asked for Consent to Search Person ["17,Y"]') ||
				arrayIncludes('actionsTakenList', 'Asked for Consent to Search Property ["19,Y"]'))){

				arrayAdd('searchBasisList', 'Consent Given [1]');
				searchConsentGiven.value = true;
				return true;
			}else{
				searchConsentGiven.value = false;
				arrayRemove('searchBasisList', 'Consent Given [1]')
				return false;
			}
		}

		function toggleSearchConducted(target: SearchTarget): void{
			if (target === SearchTarget.Both){
				if (searchConducted.value !== true){
					arrayAdd('actionsTakenList', 'Search of Property was Conducted ["20,na"]')
					arrayAdd('actionsTakenList', 'Search of Person was Conducted ["18,na"]')
				}else{
					arrayRemove('actionsTakenList', 'Search of Property was Conducted ["20,na"]')
					arrayRemove('actionsTakenList', 'Search of Person was Conducted ["18,na"]')
				}
			}

			if (target === SearchTarget.Person){
				arrayToggle('actionsTakenList', 'Search of Person was Conducted ["18,na"]')
			}

			if (target === SearchTarget.Property){
				arrayToggle('actionsTakenList', 'Search of Property was Conducted ["20,na"]')
			}

			evaluateSearchConducted();
		}

		function updateSearchConsentGiven(consentGiven: boolean, target: SearchTarget): void{
			if (target === SearchTarget.Both){
				updateSearchConsentGiven(consentGiven, SearchTarget.Person)
				updateSearchConsentGiven(consentGiven, SearchTarget.Property)
			}

			let arrayValue = undefined
			let exclusiveValue = undefined

			if (target === SearchTarget.Person){
				arrayValue = consentGiven
					? 'Asked for Consent to Search Person ["17,Y"]'
					: 'Asked for Consent to Search Person ["17,N"]'
				exclusiveValue = consentGiven
					? 'Asked for Consent to Search Person ["17,N"]'
					: 'Asked for Consent to Search Person ["17,Y"]'
			}

			if (target === SearchTarget.Property){
				arrayValue = consentGiven
					? 'Asked for Consent to Search Property ["19,Y"]'
					: 'Asked for Consent to Search Property ["19,N"]'

				exclusiveValue = consentGiven
					? 'Asked for Consent to Search Property ["19,N"]'
					: 'Asked for Consent to Search Property ["19,Y"]'
			}

			if (exclusiveValue && arrayValue){
				arrayAdd('actionsTakenList', arrayValue);
				arrayRemove('actionsTakenList', exclusiveValue);
			}

			evaluateSearchBasisConsentGiven();
		}

		function toggleSearchConsentGiven(){
			updateSearchConsentGiven(!searchConsentGiven.value, SearchTarget.Both);
		}

		function arrayAdd(arrayName: string, value: string): Array<string>{
			let lowerCaseValue: string = value.toLowerCase()
			let array: Array<string> = getArrayByName(arrayName)

			if (!array.find(element => {
				return element.toLowerCase() === lowerCaseValue
			})){
				array.push(value)
			}

			return updateArrayValue(arrayName, array);
		}

		function arrayRemove(arrayName: string, value: string): Array<string>{
			let lowerCaseValue: string = value.toLowerCase()
			let array: Array<string> = getArrayByName(arrayName)

			array = array.filter(e => e.toLowerCase() !== lowerCaseValue);
			return updateArrayValue(arrayName, array);
		}

        function arrayToggleExclusive(arrayName: string, value: string): Array<string>{
            let lowerCaseValue: string = value.toLowerCase()
            let array: Array<string> = getArrayByName(arrayName)

			if (array.find(element => {
				return element.toLowerCase() === lowerCaseValue
			})){
				array = array.filter(e => e.toLowerCase() !== lowerCaseValue);
			}else{
				array = [value];
			}

            return updateArrayValue(arrayName, array)
        }

        function arrayToggle(arrayName, value): Array<string> {
            let lowerCaseValue = value.toLowerCase()
            let array = getArrayByName(arrayName)

            if (!Array.isArray(array)) {
                array = []
            }

            for (let index = 0; index < array.length; ++index) {
                if (array[index].toLowerCase() === lowerCaseValue) {
                    array.splice(index, 1)
                    return updateArrayValue(arrayName, array)
                }
            }

            array.push(value)
            return updateArrayValue(arrayName, array)
        }

        function arrayIncludes(arrayName, value): boolean {
            let lowerCaseValue = value.toLowerCase()
            let array = getArrayByName(arrayName)

			return array.findIndex(element => element.toLowerCase() === lowerCaseValue) !== -1
        }

        function equalsIgnoringCase(a, b): boolean {
            if (typeof(a) !== "string") {
                return false
            }

            return a ? a.localeCompare(b, undefined, {sensitivity: 'base'}) === 0 : a === b
        }

        function clickLocation(event) {
            showLocation(event.target.id)
        }

        function showLocation(type) {
            switch (type) {
                case "location_type_default":
                    showDefaultLocation.value = true
                    showSchoolLocation.value = false
                    showResidenceLocation.value = false
                    break
                case "location_type_school":
                    showDefaultLocation.value = false
                    showSchoolLocation.value = true
                    showResidenceLocation.value = false
                    break
                case "location_type_residence":
                    showDefaultLocation.value = false
                    showSchoolLocation.value = false
                    showResidenceLocation.value = true
                    break
                default:
                    break;
            }
        }

        function personChanged(event) {
            // save the last person selected
            savePerson(lastPerson.value)

            // update the last person selected to the new one
            lastPerson.value = event.target.value

            // load the new person selected
            loadPerson(lastPerson.value)
        }

        function elementsByName(name) {
            let values = []

            Array.from(document.getElementsByName(name)).forEach(
                (element: HTMLInputElement) => {
                    values.push(element.value)
                }
            )

            return values
        }

        function parseBool(value) {
            if (typeof(value) === 'string') {
                value = value.trim().toLowerCase()
            }

            switch (value) {
                case true:
                case "true":
                case 1:
                case "1":
                case "yes":
                    return true
                default:
                    return false
            }
        }

        function savePerson(personId) {
            fieldErrors.value = []
            let s = store.getters["submission/getSubmission"]
            // init content
            let c = {
                "assignment": s.content.assignment,
                "experience": s.content.experience,
                "other_assignment": s.content.other_assignment,
                "form_data": s.content.form_data
            }
            
            // init form data
            let f = {
                "date": c.form_data.date,
                "time": c.form_data.time,
                "stop_duration": c.form_data.stop_duration,
                "stop_service_call": c.form_data.stop_service_call,
                "out_of_county": c.form_data.out_of_county,
                "school": c.form_data.school,
                "block_number": c.form_data.block_number,
                "street_name": c.form_data.street_name,
                "intersection": c.form_data.intersection,
                "highway_exit": c.form_data.highway_exit,
                "landmark": c.form_data.landmark,
                "city": c.form_data.city,
                "people": c.form_data.people
            }
            
            // init person record
            let r = f.people[personId]

            // init person
            let p = {
                "is_k12_student": r.is_k12_student,
                "race_ethnicity": r.race_ethnicity,
                "gender": r.gender,
                "gender_nonconforming": r.gender_nonconforming,
                "lgbt": r.lgbt,
                "age": r.age,
                "limited_english": r.limited_english,
                "disability": r.disability,
                "stop_reason": r.stop_reason,
                "student_discipline_section": r.student_discipline_section,
                "suspension_code": r.suspension_code,
                "traffic_violation": r.traffic_violation,
                "traffic_violation_code": r.traffic_violation_code,
                "reasonable_suspicion": r.reasonable_suspicion,
                "reasonable_suspicion_code": r.reasonable_suspicion_code,
                "stop_narrative": r.stop_narrative,
                "actions_taken": r.actions_taken,
                "search_basis": r.search_basis,
                "search_basis_explanation": r.search_basis_explanation,
                "property_type_seized": r.property_type_seized,
                "property_seizure_basis": r.property_seizure_basis,
                "evidence_or_contraband": r.evidence_or_contraband,
                "stop_result": r.stop_result,
                "warning_cjis_codes": r.warning_cjis_codes,
                "citation_cjis_codes": r.citation_cjis_codes,
                "cite_and_release_cjis_codes": r.cite_and_release_cjis_codes,
                "arrest_cjis_codes": r.arrest_cjis_codes,
            }
            // submission data
            c["assignment"] = elementsByName("assignment")[0]
            c["experience"] = elementsByName("experience")[0]
            c["other_assignment"] = elementsByName("other_assignment")[0]

            // form data
            f["date"] = elementsByName("date")[0]
            f["time"] = elementsByName("time")[0]
            f["stop_duration"] = elementsByName("stop_duration")[0]
            f["stop_service_call"] = parseBool(elementsByName("stop_service_call")[0])
            f["out_of_county"] = parseBool(elementsByName("out_of_county")[0])
            f["school"] = elementsByName("school")[0]
            f["block_number"] = elementsByName("block_number")[0]
            f["street_name"] = elementsByName("street_name")[0]
            f["intersection"] = elementsByName("intersection")[0]
            f["highway_exit"] = elementsByName("highway_exit")[0]
            f["landmark"] = elementsByName("landmark")[0]
            f["city"] = elementsByName("city")[0]


            let newDateVal = moment(f["date"]).utc()
            //let splittedDate = (newDateVal.getMonth() + 1).toString().padStart(2, "0") + "/" + newDateVal.getDate().toString().padStart(2, "0") + "/" + newDateVal.getFullYear()
            
            f["date"] = newDateVal


            if(showSchoolLocation.value == true){
                if(f["school"] != undefined){
                    let schoolExists = false
                    for(let school of schoolList){
                        if(school.school.toUpperCase() == f["school"].toUpperCase()){
                            schoolExists = true
                        }
                    }
                    if(schoolExists == false){
                        fieldErrors.value.push('You must select one of the DOJ approved school from the displayed list.')
                        return
                    }
                }
            }
            // person data
            p["is_k12_student"] = parseBool(elementsByName("is_k12_student")[0])
            p["race_ethnicity"] = raceEthnicityList.value
            p["gender"] = gender.value
            p["gender_nonconforming"] = parseBool(elementsByName("gender_nonconforming")[0])
          
          
            p["lgbt"] = parseBool(elementsByName("lgbt")[0])
            p["age"] = elementsByName("age")[0]
            p["limited_english"] = parseBool(elementsByName("limited_english")[0])
            p["disability"] = disabilityList.value
            p["stop_reason"] = stopReason.value
            p["student_discipline_section"] = studentDisciplineSection.value
            p["suspension_code"] = elementsByName("suspension_code")[0]
            p["traffic_violation"] = trafficViolation.value
            p["traffic_violation_code"] = elementsByName("traffic_violation_code")[0]
            p["reasonable_suspicion"] = reasonableSuspicionList.value
            p["reasonable_suspicion_code"] = elementsByName("reasonable_suspicion_code")[0]
            p["stop_narrative"] = elementsByName("stop_narrative")[0]

            p["actions_taken"] = actionsTakenList.value

            p["search_basis"] = searchBasisList.value
            p["search_basis_explanation"] = elementsByName("search_basis_explanation")[0]
            p["evidence_or_contraband"] = evidenceOrContrabandList.value
            p["property_type_seized"] = propertyTypeSeizedList.value
            p["property_seizure_basis"] = propertySeizureBasisList.value
            p["stop_result"] = stopResultList.value
            p["warning_cjis_codes"] = elementsByName("warning_cjis_codes")
            p["citation_cjis_codes"] = elementsByName("citation_cjis_codes")
            p["cite_and_release_cjis_codes"] = elementsByName("cite_and_release_cjis_codes")
            p["arrest_cjis_codes"] = elementsByName("arrest_cjis_codes")

            // save changes back to submission
            s["content"] = c
            s["content"]["form_data"] = f
            s["content"]["form_data"]["people"][personId] = p


            // save the submission locally
            store.dispatch("submission/set", JSON.parse(JSON.stringify(s)))
        }

        function handleSaveSuccess(response) {
            // the API will return a successful 200 response, but may also embed errors in the response

            if (hasResponseErrors(response)) {
                handleResponseErrors(response)
                return
            }

            if (store.getters["form_data/getActiveFormRecordStatus"] === "Not Saved") {
                let submission = store.getters["submission/getSubmission"]
                let updatedSubmission = {
                    ...store.getters["form_data/getFormRecord"],
                    ...submission.content.form_data,
                    "status": "Saved"
                }
                store.dispatch("form_data/setFormRecord", updatedSubmission)
            }

            //do stuff here
            store.dispatch("dialog/setMessage", "Saved")
            store.dispatch("dialog/setIconType", "has-text-info")
            store.dispatch("dialog/setIcon", "fa-cloud-upload")
            store.dispatch("dialog/setButtonLeftFunc", null)
            store.dispatch("dialog/setButtonRightFunc", null)
            store.dispatch("dialog/setButtonXText", null)
            store.dispatch("dialog/setButtonXFunc", null)
            localStorage.removeItem('rejection_notes')
        

            setTimeout((path) => {
                store.dispatch("dialog/closeDialog")
                let domainType = sessionStorage.getItem('domainType')
                if(domainType && domainType == 'dashboard'){
                    location.reload()
                } else {
                    if (window.location.href.includes('review')) {
                        router.push('/start')
                    }
                    if (window.location.href.includes('start')) {
                        location.reload()
                    }
                }
            }, 1500)
        }

        function handleSaveFailed(error) {
            store.dispatch("dialog/setMessage", "Save Failed")

            setTimeout((path) => {
                store.dispatch("dialog/closeDialog")
            }, 1500)
        }

        function save(params) {
            // display the save modal
            store.dispatch("dialog/setMessage", "Saving...")
            store.dispatch("dialog/setIconType", "has-text-info")
            store.dispatch("dialog/setIcon", "fa-cloud-upload")
            store.dispatch("dialog/setButtonLeftFunc", null)
            store.dispatch("dialog/setButtonRightFunc", null)
            store.dispatch("dialog/setButtonXText", "X")
            store.dispatch("dialog/setButtonXFunc", null)

            if (store.getters["form_data/getActiveFormRecordStatus"] === "Saved") {
                store.dispatch("dialog/setMessage", "Cannot Edit")

                setTimeout((path) => {
                    store.dispatch("dialog/closeDialog")
                }, 1500)

                store.dispatch("dialog/openDialog")
                return
            }

            store.dispatch("dialog/openDialog")

            // save the current person
            savePerson(lastPerson.value)

            // load the complete submission
            let submission = store.getters["submission/getSubmission"]
            let time_to_complete = "00:00:00";
            if(params && params.timeDiff != null){
                //submission.content.form_data['time_to_complete'] = params.timeDiff
                time_to_complete = params.timeDiff

            }

            // POST or PUT operation? check the submission id
            if (submission.id === "") {
                
                // POST operation since we don't know the submission id
                let ripaOfficerId = store.getters["session/getRandomId"]
                let originatingAgencyIdentifier = store.getters["session/getOriginatingAgencyIdentifier"]
                let formId = store.getters["form/getFormId"]
                store.dispatch("submission/create", {
                    "formId": formId,
                    "originatingAgencyIdentifier": originatingAgencyIdentifier,
                    "ripaOfficerId": ripaOfficerId,
                    "submission": submission,
                    "time_to_complete": time_to_complete
                }).then((response) => {

                    handleSaveSuccess(response)
                }).catch((error) => {
                    handleSaveFailed(error)
                })
            } else {
                // PUT operation since we have an existing submission id
                store.dispatch("submission/put", {
                    "submissionId": submission.id,
                    "submissionUserId": submission.user_id,
                    "content": submission.content
                }).then((response) => {

                    handleSaveSuccess(response)
                }).catch((error) => {
                    handleSaveFailed(error)
                })
            }
        }

        onMounted(() => {
            emitter.on("save-formedit", (params) => {
                save(params)
            })
            emitter.on("setSchoolAddressForm", (params) => {
                let isnum = /^\d+$/.test(params.street.split(' ')[0]);
				blockNumber.value = params.street
                streetName.value = params.street

                if(isnum == true) {
                    blockNumber.value = params.street.split(' ')[0]

                    let streetArray = params.street.split(' ')
                    streetArray[0] = '';
                    let newStreetVal = streetArray.join(' ')
                    streetName.value = newStreetVal.trim()
                } else {
                    streetName.value = params.street
                }

			})
        })

        onUnmounted(() => {
            emitter.off("save-formedit")
            emitter.off("setSchoolAddressForm")
        })

        function reload() {
            if (parseSubmission()) {
                let personId = localStorage.getItem('activePersonRecord') || 0
                loadPerson(personId)
                lastPerson.value = +personId
            }

            if (school.value) {
                showLocation("location_type_school")
            } else if (blockNumber.value && streetName.value) {
                showLocation("location_type_default")
            } else if (intersection.value || highwayExit.value || landmark.value) {
                showLocation("location_type_residence")
            } else {
                showLocation("location_type_default")
            }
        }

        function hasResponseErrors(response) {

            return (response.data.service_errors && response.data.service_errors.length > 0)
                || (response.data.validation_errors && response.data.validation_errors.length > 0)
        }

        function handleResponseErrors(response) {
            errors.value = []
            errors.value = fieldErrors.value
            
            if (response.data.service_errors) {
                for (let i of response.data.service_errors) {
                    errors.value.push(i)
                }
            }

            if (response.data.validation_errors) {
                for (let i of response.data.validation_errors) {
                    errors.value.push(i)
                }
            }
            handleSaveFailed(errors.value)
        }

        reload()

        return {
            lastPerson,
            personOptions,
            ori,
            ripaOfficerId,
            assignment,
            experience,
            otherAssignment,
            stopId,
            date,
            time,
            stopDuration,
            stopCallService,
            outOfCounty,
            school,
            blockNumber,
            streetName,
            intersection,
            highwayExit,
            landmark,
            city,
            raceEthnicityList,
            gender,
            genderNonConforming,
            lgbt,
            age,
            limitedEnglish,
            isK12Student,
            disabilityList,
            reasonableSuspicionList,
            reasonableSuspicionCode,
            stopReason,
            stopNarrative,
            studentDisciplineSection,
            suspensionCode,
            trafficViolation,
            trafficViolationCode,
            actionsTakenList,
            searchBasisList,
            searchBasisExplanation,
            evidenceOrContrabandList,
            propertyTypeSeizedList,
            propertySeizureBasisList,
            stopResultList,
            warningCjisCodeList,
            citationCjisCodeList,
            citeAndReleaseCjisCodeList,
            arrestCjisCodeList,
            showDefaultLocation,
            showSchoolLocation,
            showResidenceLocation,
            showCity,
            errors,
			SearchTarget,
			searchConsentGiven,
			searchConducted,
			updateSearchConsentGiven,
			toggleSearchConducted,
            setValue,
            arrayIncludes,
            arrayToggle,
            arrayToggleExclusive,
			toggleSearchConsentGiven,
            toggleAskedForConsentToSearchPerson,
            toggleAskedForConsentToSearchProperty,
			evaluateSearchConducted,
			evaluateSearchBasisConsentGiven,
            askedForConsentToSearchProperty,
            askedForConsentToSearchPerson,
            equalsIgnoringCase,
            clickLocation,
            personChanged,
            reload
        }
    }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f8d4b64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "control has-icons-left" }
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "button-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormEditLabel = _resolveComponent("FormEditLabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormEditLabel, {
      class: "is-marginless",
      for: $props.id,
      label: $props.label,
      sublabel: $props.sublabel
    }, null, 8, ["for", "label", "sublabel"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "button is-fullwidth",
        id: $props.id,
        type: "button"
      }, [
        _createElementVNode("div", _hoisted_4, _toDisplayString($setup.buttonText), 1)
      ], 8, _hoisted_3),
      _createElementVNode("span", {
        class: _normalizeClass(["icon is-small left-icon is-left", {'icon-radio': ($props.icon === 'radio'), 'icon-checkmark': ($props.icon === 'checkmark'), 'unmarked': !_ctx.isMarked}])
      }, [
        ($props.marked)
          ? (_openBlock(), _createElementBlock("i", {
              key: 0,
              class: _normalizeClass(["fas", $setup.iconClass])
            }, null, 2))
          : _createCommentVNode("", true)
      ], 2)
    ])
  ]))
}
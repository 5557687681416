
import {computed, ref, onMounted, onUnmounted} from "vue"
import FormEditLabel from "@/common/form_edit/FormEditLabel.vue"
import useEmitter from "@/composables/UseEmitter"

export default {
	name: "FormEditTextInput",
	components: {FormEditLabel},
	props: {
		id: String,
		type: String,
		name: String,
		required: String,
		label: String,
		sublabel: String,
		value: String,
		subscribe: String,
		min: String,
		max: String,
		pattern: String,
		comment: String
	},
	setup(props) {
		let inputId = ref(props.id)
		const emitter = useEmitter()
		let inputValue = computed(() => {
			if (!props.value) {
				return null
			}

			switch (props.type) {
				case 'date': {
					return props.value.replace(/(.*)\/(.*)\/(.*)/, '$3-$1-$2')
				}
				default:
					return props.value
			}

			// switch (typeof (props.value)) {
			// 	case 'string':
			// 		return props.value.replace(/ \[.*\]$/, '')
			// 	default:
			// 		return props.value
			// }
		})


		// TODO handle these properties
		// required
		// min
		// max
		// pattern
		// subscribe

		return {
			inputId,
			inputValue
		}
	}
}

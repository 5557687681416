
import {ref} from "vue"
import FormEditLabel from "@/common/form_edit/FormEditLabel.vue"

export default {
	name: "FormEditYesNoButtons",
	components: {FormEditLabel},
	props: [
		"id",
		"name",
		"label",
		"sublabel",
		"value"
	],
	emits: ["click-yes", "click-no"],
	setup(props, {emit}) {
		let yesClass = ref("")
		let noClass = ref("")
		let inputValue = ref(props.value)

		function clickYes(event) {
			if (inputValue.value !== true){
				emit("click-yes", event)
			}
			inputValue.value = true
			reload()
		}

		function clickNo(event) {
			if (inputValue.value !== false){
				emit("click-no", event)
			}
			inputValue.value = false
			reload()
		}

		function reload() {
			if (inputValue.value) {
				yesClass.value = "is-info"
				noClass.value = "is-outlined is-transparent is-white"
			} else {
				yesClass.value = "is-outlined is-transparent is-white"
				noClass.value = "is-info"
			}
		}

		reload()

		return {inputValue, yesClass, noClass, clickYes, clickNo, reload}
	}
}

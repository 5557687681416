
import {ref} from "vue"
import FormEditLabel from "@/common/form_edit/FormEditLabel.vue"

export default {
	name: "FormEditSelectInput",
	components: {FormEditLabel},
	emits: ['select-changed'],
	setup(props, {attrs, emit}) {
		let model = ref()

		const vid: string = attrs.vid
		const name: string = attrs.name
		const label: string = attrs.label
		const action: string = attrs.action
		const options: unknown = attrs.options
		let className: string = attrs.class_name
		let selected: number = attrs.selected

		function selectChanged(event) {
			emit('select-changed', event)
		}

		return { model, vid, name, label, action, options, className, selected, selectChanged }
	},
	mounted() {
		let selectObject = document.getElementById(this.vid) as HTMLSelectElement
		selectObject.selectedIndex = this.selected
	}
}

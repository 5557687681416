import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03c29926"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "control" }
const _hoisted_3 = ["id", "name", "type", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormEditLabel = _resolveComponent("FormEditLabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormEditLabel, {
      class: "is-marginless",
      for: $setup.inputId,
      label: $props.label,
      sublabel: $props.sublabel,
      action: ""
    }, null, 8, ["for", "label", "sublabel"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        id: $setup.inputId,
        name: $props.name,
        type: $props.type,
        value: $setup.inputValue
      }, null, 8, _hoisted_3)
    ])
  ]))
}
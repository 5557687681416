import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d94f554"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "control" }
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormEditLabel = _resolveComponent("FormEditLabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormEditLabel, {
      class: "is-marginless",
      for: $props.id,
      label: $props.label,
      sublabel: $props.sublabel
    }, null, 8, ["for", "label", "sublabel"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "button is-fullwidth is-outlined is-transparent is-white is-info is-5-mobile",
        id: $props.id,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.updateLocation && $setup.updateLocation(...args))),
        type: "button",
        value: "content"
      }, _toDisplayString($props.content), 9, _hoisted_3)
    ])
  ]))
}
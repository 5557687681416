
import { ref } from "vue"

export default {
	name: "FormEditLabel",
	setup(props, context) {
		let forId = ref(context.attrs.for)
		let className = ref(context.attrs.class)
		let labelText = ref(context.attrs.label)
		let subLabelText = ref(context.attrs.sublabel)
		let actionText = ref(context.attrs.action)

		return { forId, className, labelText, subLabelText, actionText }
	}
}

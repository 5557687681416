import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6610d7bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "control" }
const _hoisted_3 = { class: "columns is-mobile" }
const _hoisted_4 = { class: "column is-half-mobile" }
const _hoisted_5 = ["name", "value"]
const _hoisted_6 = { class: "column is-half-mobile" }
const _hoisted_7 = ["name", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormEditLabel = _resolveComponent("FormEditLabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormEditLabel, {
      class: "is-marginless",
      for: $props.id,
      label: $props.label,
      sublabel: $props.sublabel,
      action: ""
    }, null, 8, ["for", "label", "sublabel"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            class: _normalizeClass(["button is-fullwidth is-static-with-color", $setup.yesClass]),
            name: $props.name,
            value: $setup.inputValue,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.clickYes && $setup.clickYes(...args)))
          }, "YES", 10, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("button", {
            class: _normalizeClass(["button is-fullwidth is-static-with-color", $setup.noClass]),
            name: $props.name,
            value: $setup.inputValue,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.clickNo && $setup.clickNo(...args)))
          }, "NO", 10, _hoisted_7)
        ])
      ])
    ])
  ]))
}
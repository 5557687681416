
import FormEditLabel from "@/common/form_edit/FormEditLabel.vue"

export default {
	name: "FormEditGeoLocationButton",
	components: {FormEditLabel},
	props: [
		"id",
		"label",
		"sublabel",
		"content"
	],
	setup(props) {
		function updateLocation() {
			console.log("UNIMPLEMENTED UPDATE LOCATION")
			return
		}

		return {updateLocation}
	}
}
